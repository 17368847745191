import { Icon } from '@appliedsystems/applied-design-system';
import React from 'react';

export const Loading = ({ height = '100vh' }: { height?: number | string }): React.ReactElement => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: height,
      }}
    >
      <Icon icon="SpinnerIcon" size={32} />
    </div>
  );
};
