import { TextField, useFormContext } from '@appliedsystems/applied-design-system';
import { ErrorCode } from '@appliedsystems/payments-core';
import React, { useEffect } from 'react';
import { usePaymentsTranslation } from '../../../hooks/usePaymentsTranslation';

type Props = {
  error?: ErrorCode;
};

export const VerificationCodeField = ({ error }: Props) => {
  const { t } = usePaymentsTranslation();
  const { setError } = useFormContext<{ code: string }>();

  useEffect(() => {
    if (error) {
      setError('code', { message: t('VERIFICATION_CODE_NOT_VALID') }, { shouldFocus: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, setError]);

  return <TextField name="code" label={t('VERIFICATION_CODE')} className="mb-50" />;
};
