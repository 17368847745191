import { BrandType, InlineAlert } from '@appliedsystems/applied-design-system';
import { Dict } from '@appliedsystems/payments-core';
import { datadogLogs } from '@datadog/browser-logs';
import React from 'react';
import { TranslationKey, usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';

export type ErrorMessage = [translationKey: TranslationKey, replacements?: Dict<string>];

export const ErrorAlert = ({ errorMessage, className }: { errorMessage: ErrorMessage; className?: string }) => {
  const { t } = usePaymentsTranslation();
  const sessionId = datadogLogs.getInternalContext()?.session_id;

  return (
    <div style={{ whiteSpace: 'pre-wrap' }} className={className}>
      <InlineAlert type={BrandType.Error} closeable={false}>
        {t(errorMessage[0], undefined, {
          ...errorMessage[1],
          id: errorMessage[1]?.id ?? sessionId,
        } as any)}
      </InlineAlert>
    </div>
  );
};
