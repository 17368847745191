import { Button, Label, SectionTypes, SectionV2 } from '@appliedsystems/applied-design-system';
import React, { useEffect } from 'react';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { Locale } from '../../store/Locale';
import { AddESignModal } from '../ESign/ESignModal';
import { base64MockEncodedPfaString } from '../ESign/MockEncodedPfaString';
import { PfInfo } from '../HostedPaymentPageContainer/types';
import { useHppDataStore } from '../HostedPaymentPageContainer/useHppData';
import { PremiumFinanceQuoteInformation } from '../PremiumFinanceQuoteInformation/PremiumFinanceQuoteInformation';
import greenCheck from './check-circle.svg';
import classes from './PaymentOptionDetails.module.scss';

type Props = {
  pfInfo: PfInfo;
  isPfaSigned: boolean;
  setIsPfaSigned: (isSigned: boolean) => void;
};

export const PaymentOptionDetails = ({ pfInfo, isPfaSigned, setIsPfaSigned }: Props): React.ReactElement => {
  const { t } = usePaymentsTranslation();
  const { locale } = Locale.useContainer();
  const { hppData, setHppData } = useHppDataStore();

  // pdfData string hardCoded until createQuote call is implented
  const [isOpenEsignModal, setIsOpenEsignModal] = React.useState(false);
  // assuming this useEffect won't be necessary once we update with real data
  useEffect(() => {
    setHppData({
      pf: hppData.pf?.quoteId
        ? {
            quoteId: hppData.pf.quoteId,
            financeableInvoices: hppData.pf.financeableInvoices,
            company: hppData.pf.company,
            amountFinanced: hppData.pf.amountFinanced,
            downPaymentAmount: hppData.pf.downPaymentAmount,
            currency: hppData.pf.currency,
            monthlyAmount: hppData.pf.monthlyAmount,
            numberOfPayments: hppData.pf.numberOfPayments,
            nextPaymentDueDate: hppData.pf.nextPaymentDueDate,
            base64Pfa: base64MockEncodedPfaString,
            signeeInfo: hppData.pf.signeeInfo,
          }
        : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <SectionV2 title={t('PREMIUM_FINANCE_AGREEMENT')} type={SectionTypes.H3}>
        <div className="mb-100">
          <span>{t('REVIEW_AND_SIGN_DESCRIPTION')}</span>
        </div>

        <Label required>{t('PREMIUM_FINANCE_AGREEMENT')}</Label>
        <Button type="secondary" icon="PencilIcon" onClick={() => setIsOpenEsignModal(true)}>
          {t('REVIEW_AND_SIGN_BUTTON')}
        </Button>

        {isOpenEsignModal && hppData.pf?.quoteId && hppData.pf.base64Pfa && (
          <AddESignModal
            isOpenEsignModal={isOpenEsignModal}
            onClose={() => setIsOpenEsignModal(false)}
            base64Pfa={hppData.pf.base64Pfa}
            isPfaSigned={isPfaSigned}
            setIsPfaSigned={setIsPfaSigned}
            signeeName={hppData.pf.signeeInfo?.name || ''}
            signeePhoneNumber={hppData.pf.signeeInfo?.phoneNumber || ''}
          />
        )}
      </SectionV2>
      {isPfaSigned && (
        <div className={classes.successIcon}>
          <img src={greenCheck} alt={t('CONFIRMATION_SUCCESS_ALT_TEXT')} />
          <div className={classes.successText}>
            <span>{t('SIGNED_SUCCESSFULLY')}</span>
          </div>
        </div>
      )}
      <SectionV2 title={t('FINANCING_SUMMARY_TITLE')} type={SectionTypes.H3}>
        <div className="mb-100">
          <span>
            {t('FINANCING_SUMMARY_DESCRIPTION', undefined, { company: pfInfo.quoteId ? pfInfo.company : '' } as any)}
          </span>
        </div>

        <Button type="secondary" className="mb-200" disabled>
          {t('SET_UP_PAYMENTS_INSTALLMENTS')}
        </Button>
        <PremiumFinanceQuoteInformation locale={locale} pfInfo={pfInfo} />
      </SectionV2>
    </>
  );
};
