import {
  ADSChangeEvent,
  Checkbox,
  Flex,
  PhoneField,
  Radio,
  RadioGroup,
  TextField,
  useFormContext,
} from '@appliedsystems/applied-design-system';
import { HppSingleInvoiceValidationMode } from '@appliedsystems/payments-core';
import React, { useEffect } from 'react';
import { useAccountManagement } from '../../hooks/useAccountManagement';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { useAgencyDetailsStore } from '../../store/AgencyDetail';
import { getConfig } from '../../util/config';
import { getWorkflow } from '../../util/getWorkflow';
import { PayBySelection } from '../HostedPaymentPageContainer/HostedPaymentPageContainer';
import { useHppDataStore } from '../HostedPaymentPageContainer/useHppData';
import { AccountInfoFormSchema } from './AccountInformationForm';
import classes from './AccountInformationForm.module.scss';

export const AccountInformationFields = ({ onDataChange }: { onDataChange: () => void }) => {
  const { data: agencyDetails } = useAgencyDetailsStore();
  const { t } = usePaymentsTranslation();
  const formContext = useFormContext<AccountInfoFormSchema>();
  const { customerUser } = useAccountManagement(agencyDetails?.appliedProductId, agencyDetails?.token);
  const { setValue, watch, getValues, clearErrors } = formContext;
  const { hppData, setHppData } = useHppDataStore();

  useEffect(() => {
    const subscription = watch((values, { name, type }) => {
      // Always update workflow
      if (name === 'paymentWorkflow') {
        setHppData({ paymentWorkflow: values.paymentWorkflow });
      }

      if (type === 'change') {
        setHppData({
          firstName: values.firstName,
          lastName: values.lastName,
          userEmail: values.userEmail,
          businessName: values.businessName,
          postalCode: values.postalCode,
          invoiceNumber: values.invoiceNumber,
          phoneNumber: values.phoneNumber?.phoneNumber || '',
          payBy: values.payBy,
          paymentWorkflow: values.paymentWorkflow,
          accountCode: values.accountCode,
        });

        // When changing payBy, clean up bypassAccountCode
        // to trigger the workflow re-evaluation
        if (name === 'payBy') {
          setValue('bypassAccountCode', false);
        }

        onDataChange();
      }
    });
    return () => subscription.unsubscribe();
  }, [clearErrors, formContext, hppData.payBy, onDataChange, setHppData, setValue, watch]);

  // Controls
  const showRadioGroup = agencyDetails?.payByInvoiceEnabled && agencyDetails?.singleAmountEnabled;

  // FYI: The field in the database is _singleInvoiceValidationMode_
  // but it is used for the Single Amount flow
  const showAccountBypassCheckbox =
    agencyDetails?.singleInvoiceValidationMode === HppSingleInvoiceValidationMode.BYPASS_ENABLED &&
    hppData.payBy === PayBySelection.AMOUNT;

  // Use effect for workflow changes
  useEffect(() => {
    setValue('paymentWorkflow', getWorkflow(hppData.payBy, agencyDetails));
  }, [agencyDetails, hppData.payBy, setValue]);

  // set customer user fields if available
  useEffect(() => {
    if (!customerUser) return;
    if (customerUser.firstName) setValue('firstName', customerUser.firstName);
    if (customerUser.lastName) setValue('lastName', customerUser.lastName);
    if (customerUser.email) setValue('userEmail', customerUser.email);
    if (customerUser.accountNumber) setValue('accountCode', customerUser.accountNumber);
  }, [customerUser, setValue]);

  // auto populate required fields on local to ease development
  useEffect(() => {
    if (getConfig('REACT_APP_TIER') === 'local') {
      setValue('phoneNumber', {
        phoneNumber: '(708) 555-1212 ',
        countryCode: 'US',
        countryCode3: 'USA',
        E164Number: '+17085551212',
        isValid: true,
      } as any);
      setValue('accountCode', 'CROMCON-01');
      setValue('postalCode', '60443');
      setValue('invoiceNumber', '806'); // 806 must never be paid!
      if (!customerUser) {
        setValue('firstName', 'Alex');
        setValue('lastName', 'Smith');
        setValue('userEmail', 'example@test.com');
      }

      // Link to hpp data in local (otherwise the values are filled but hppData is empty)
      setHppData(getValues());
    }
    // Only call this once (otherwise valid data gets
    // overwritten when we open the first accordion)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Component template
  return (
    <>
      {showRadioGroup ? (
        <>
          <Flex>{t('SELECT_PAY_BY_OPTION')}</Flex>
          <Flex className="mt-100">
            <div className={classes.payBy}>{t('PAY_BY')}</div>
            <RadioGroup
              name="payBy"
              onChange={(event: ADSChangeEvent) => {
                setHppData({ payBy: event.target.value });
              }}
            >
              <Radio value={PayBySelection.INVOICE}>{t('INVOICE')}</Radio>
              <Radio value={PayBySelection.AMOUNT}>{t('AMOUNT')}</Radio>
            </RadioGroup>
          </Flex>
        </>
      ) : (
        <Flex>{t('NO_SELECT_PAY_BY_OPTION')}</Flex>
      )}

      {/* The sizes and layout distribuition of the fields is different enough to warrant a case-by-case render */}
      {hppData.payBy === PayBySelection.NONE && (
        <Flex className="mt-100">
          <div className={classes.flexGrowOne}>
            <TextField name="firstName" />
          </div>
          <div className={classes.flexGrowOne}>
            <TextField name="lastName" />
          </div>
          <div className={classes.flexGrowOne}>
            <TextField name="userEmail" />
          </div>
          <div className={classes.flexGrowOne}>
            <TextField name="businessName" testId="businessName" />
          </div>
        </Flex>
      )}

      {hppData.payBy === PayBySelection.AMOUNT && (
        <>
          <Flex className="mt-100">
            <div className={classes.flexGrowOne}>
              <TextField name="firstName" />
            </div>
            <div className={classes.flexGrowOne}>
              <TextField name="lastName" />
            </div>
            <div className={classes.flexGrowOne}>
              <TextField name="userEmail" />
            </div>
          </Flex>
          <Flex className={`mt-150 ${classes.flexNoWrap}`}>
            <div className={classes.flexGrowOne}>
              <TextField name="businessName" testId="businessName" />
            </div>
            <div className={classes.flexColumnGrow}>
              <TextField
                name="accountCode"
                testId="accountCode"
                disabled={getValues('bypassAccountCode') && hppData.payBy === PayBySelection.AMOUNT}
                helpText={t('ACCOUNT_NUMBER_TOOLTIP')}
              />
              {showAccountBypassCheckbox && (
                <Checkbox name="bypassAccountCode" testId="bypassAccountCodeCheckbox">
                  {t('PROCEED_WITHOUT_ACCOUNT_NUMBER')}
                </Checkbox>
              )}
            </div>
            <div className={classes.flexGrowOne}>
              <PhoneField name="phoneNumber" hideExt />
            </div>
            <div className={classes.flexGrowOne}>
              <TextField name="postalCode" />
            </div>
          </Flex>
        </>
      )}

      {hppData.payBy === PayBySelection.INVOICE && (
        <>
          <Flex className={`mt-100 ${classes.flexNoWrap}`}>
            <div className={classes.flexGrowOne}>
              <TextField name="firstName" />
            </div>
            <div className={classes.flexGrowOne}>
              <TextField name="lastName" />
            </div>
            <div className={classes.flexGrowOne}>
              <TextField name="userEmail" />
            </div>
          </Flex>
          <Flex className={`mt-150 ${classes.flexNoWrap}`}>
            <div className={classes.flexNoGrowBasis30}>
              <TextField name="businessName" />
            </div>
            <div className={classes.flexNoGrowBasis30}>
              <TextField name="accountCode" testId="accountCode" helpText={t('ACCOUNT_NUMBER_TOOLTIP')} />
            </div>
            <div className={classes.flexNoGrowBasis15}>
              <TextField name="invoiceNumber" />
            </div>
            <div className={classes.flexNoGrowBasis15}>
              <TextField name="postalCode" />
            </div>
          </Flex>
        </>
      )}
    </>
  );
};
