import {
  Col,
  DataGrid,
  DatagridCheckboxHelpers,
  DataGridColumnType,
  Flex,
  Row,
  SortDirection,
} from '@appliedsystems/applied-design-system';
import { CurrencyCode, EpicInvoice, LocaleCode, toIntlFormat } from '@appliedsystems/payments-core';
import { groupBy, map, mapValues, minBy } from '@s-libs/micro-dash';
import React from 'react';
import { usePaymentsTranslation } from '../../../hooks/usePaymentsTranslation';
import classes from './InvoiceDataGrid.module.scss';

export type InvoiceGroup = {
  invoiceNumber: string;
  dueDate: string;
  amountDue: string;
  amountDueMinorUnits: number;
};

type Props = {
  invoices: EpicInvoice[];
  onChange: (invoices: InvoiceGroup[]) => void;
  locale: LocaleCode;
  preSelectedInvoices?: string[];
};

const LocaleCurrencyMap: { [locale in LocaleCode]: CurrencyCode } = {
  'en-US': CurrencyCode.USD,
  'en-CA': CurrencyCode.CAD,
  'fr-CA': CurrencyCode.CAD,
  'en-GB': CurrencyCode.GBP,
};

export const InvoiceDataGrid = ({ invoices, locale, onChange, preSelectedInvoices }: Props) => {
  const { t } = usePaymentsTranslation();
  const [rows, setRows] = React.useState<InvoiceGroup[]>([]);
  const rowsRef = React.useRef<InvoiceGroup[]>([]);
  const checkboxes = React.useRef<DatagridCheckboxHelpers | null>(null);
  const currencyCode = LocaleCurrencyMap[locale];

  const columns = React.useMemo(
    (): DataGridColumnType[] => [
      {
        name: 'invoiceNumber',
        title: t('INVOICE_NUMBER'),
        sorted: SortDirection.Asc,
      },
      { name: 'dueDate', title: t('DUE_DATE') },
      { name: 'amountDue', title: t('AMOUNT_DUE') },
    ],
    [t],
  );

  React.useEffect(() => {
    if (invoices) {
      const groupedInvoices = groupBy(invoices, (e) => e.invoiceNumber);
      const aggregateValues = mapValues(groupedInvoices, (group) => ({
        amountDue: group.reduce(
          (sum, invoice) => sum + (+invoice.amountDue.units * 100 + invoice.amountDue.partialUnits),
          0,
        ),
        dueDate: minBy(group, (invoice) => invoice.dueDate)?.dueDate,
      }));
      const result = map(aggregateValues, (value, key) => ({
        invoiceNumber: key,
        amountDue: toIntlFormat({ amount: value.amountDue, currencyCode }, locale),
        amountDueMinorUnits: value.amountDue,
        dueDate: new Date(value.dueDate).toLocaleDateString(locale),
      }));
      setRows(result);
      rowsRef.current = result;

      // When rows change, disregard any previous selections
      checkboxes?.current?.checkNone();
      if (preSelectedInvoices) {
        checkboxes?.current?.toggleChecked(
          // This typescript cast is necessary because
          // rowIdField is a string, but toggleChecked
          // expects an array of numbers
          preSelectedInvoices as unknown as number[],
        );
      }
    }
  }, [currencyCode, invoices, locale, preSelectedInvoices]);

  return (
    <Flex>
      <Row>
        <Col xs={12}>
          <DataGrid
            testId="invoiceDataGridTest"
            rowIdField="invoiceNumber"
            columns={columns}
            rows={rows}
            rowChildContainer={(row) => {
              const childRows = invoices?.filter((e) => e.invoiceNumber === row.invoiceNumber) as EpicInvoice[];
              return (
                <div>
                  <table className={classes.flexTable}>
                    <thead>
                      <tr>
                        <th>{t('ITEM_NUMBER')}</th>
                        <th>{t('DESCRIPTION')}</th>
                        <th>{t('POLICY_NUMBER')}</th>
                        <th>{t('DUE_DATE')}</th>
                        <th>{t('AMOUNT_DUE')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {childRows.map((e) => {
                        return (
                          <tr key={`row-${e.transactionId}`}>
                            <td data-label={t('ITEM_NUMBER')}>{e.itemNumber}</td>
                            <td data-label={t('DESCRIPTION')}>{e.description}</td>
                            <td data-label={t('POLICY_NUMBER')}>{e.policyNumber}</td>
                            <td data-label={t('DUE_DATE')}>{new Date(e.dueDate).toLocaleDateString(locale)}</td>

                            <td data-label={t('AMOUNT_DUE')}>
                              {toIntlFormat(
                                {
                                  amount: +e.amountDue.units * 100 + e.amountDue.partialUnits,
                                  currencyCode,
                                },
                                locale,
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              );
            }}
            checkbox={{
              enabled: true,
              helpers: (helpers: DatagridCheckboxHelpers) => {
                checkboxes.current = helpers;
              },
            }}
            onCheckedRowsChange={(_data, helpers) => {
              const checked = helpers.getChecked();
              const ids = checked.ids.map((e) => e.toString());
              const checkedInvoices = rows.filter((e) => ids.includes(e.invoiceNumber));
              onChange(checkedInvoices);
            }}
            searchable={{
              enabled: rows.length > 1,
            }}
          />
        </Col>
      </Row>
    </Flex>
  );
};
