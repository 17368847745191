import { BrandType, useNotifications } from '@appliedsystems/applied-design-system';
import { ErrorCode } from '@appliedsystems/payments-core';
import { useCallback } from 'react';
import { ApiClient } from '../api/ApiClient';
import { usePaymentsTranslation } from './usePaymentsTranslation';

export const useCustomerUserRequestVerificationCode = () => {
  const { addNotification } = useNotifications();
  const { t } = usePaymentsTranslation();
  const requestVerificationCode = useCallback(
    async (paymentSessionToken: string, email: string, onSuccess?: () => void) => {
      try {
        const response = await ApiClient.getInstance(paymentSessionToken).requestCustomerUserVerificationCode({
          email,
        });

        if (response.status !== 'ok' && response.additionalDetails?.[0]?.errorCode === ErrorCode.TooManyAttempts) {
          addNotification({
            type: BrandType.Error,
            title: t('ERROR'),
            content: t('TOO_MANY_ATTEMPTS'),
          });
          return;
        }

        if (response.status !== 'ok') {
          console.error('Unexpected response status', response.status);
          throw new Error('Unexpected response status');
        }

        onSuccess?.();
      } catch (error) {
        console.error('Failed to request verification code', error);
        addNotification({
          type: BrandType.Error,
          title: t('ERROR'),
          content: t('FAILED_TO_SEND_VERIFICATION_CODE'),
        });
      }
    },
    [addNotification, t],
  );

  return {
    requestVerificationCode,
  };
};
