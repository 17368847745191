export interface BrandThemeStyles {
  name: string;
  imageId: string;
  primaryColor: string;
}

export const DefaultBrandTheme: BrandThemeStyles = {
  name: 'Applied Systems',
  imageId: 'https://www1.appliedsystems.com/globalassets/all-images/logos/headerlogo1.svg',
  primaryColor: '#335eea',
};
