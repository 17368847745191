import {
  ADSAnyObject,
  ADSInputEvent,
  boolean,
  BrandType,
  Button,
  Checkbox,
  Col,
  FieldSkeleton,
  Flex,
  Form,
  FormMethods,
  H2,
  InlineAlert,
  PhoneField,
  phoneNumber,
  Row,
  string,
  TextField,
  useFormContext,
} from '@appliedsystems/applied-design-system';
import { CountryCode, getCountryCode, LocaleCode } from '@appliedsystems/payments-core';
import { datadogLogs } from '@datadog/browser-logs';
import classNames from 'classnames';
import React from 'react';
import * as yup from 'yup';
import { ApiClient } from '../../../api/ApiClient';
import { usePaymentsTranslation } from '../../../hooks/usePaymentsTranslation';
import { Translation } from '../../../localization/translations';
import { useAccountManagementStore } from '../../../store/AccountManagement';
import { Locale } from '../../../store/Locale';
import classes from '../HostedPaymentPage.module.scss';
import { useWorkflowState } from './WorkflowContext';

export const SkeletonForm = () => (
  <>
    <Flex>
      <Row>
        <Col xs={12}>
          <FieldSkeleton />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <FieldSkeleton />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <FieldSkeleton />
        </Col>
      </Row>
    </Flex>
  </>
);

type Props = {
  hppToken: string | null;
  locale: LocaleCode;
  onSuccess: (data?: SingleAmountSchema) => void;
  readonly: boolean;
  initialLoadingState: boolean;
  allowRateLimitBypass: boolean;
  allowManualBypass: boolean;
};

export type SingleAmountSchema = {
  accountNumber: string;
  phoneNumber: string;
  postalCode: string;
  clientId: string;
};

export const makeSchema = (defaultValues: SingleAmountSchema, t: (key: keyof Translation) => string) => {
  return yup.object({
    accountNumber: string()
      .default(defaultValues.accountNumber) // for testing in development: ABBOTTBE01
      .when('manualBypassEnabled', {
        is: true,
        then: (schema) => schema.optional(),
        otherwise: (schema) => schema.required(t('ERROR_ACCOUNT_NUMBER_REQUIRED')),
      }),
    manualBypassEnabled: boolean().default(false),
    phoneNumber: phoneNumber({ defaultCountry: CountryCode.US })
      .default(defaultValues.phoneNumber) // for testing in development: 9895551473
      .required(t('ERROR_PHONE_NUMBER_REQUIRED')),
    postalCode: string()
      .default(defaultValues.postalCode) // for testing in development: 71909
      .required(t('ERROR_POSTAL_CODE_REQUIRED')),
  });
};

const FormFields = ({
  readonly,
  handleADSInputEvent,
  allowManualBypass,
}: {
  readonly: boolean;
  handleADSInputEvent: (e: ADSInputEvent) => void;
  allowManualBypass: boolean;
}) => {
  const { t } = usePaymentsTranslation();
  const { useWatch } = useFormContext<yup.InferType<ReturnType<typeof makeSchema>>>();
  const { manualBypassEnabled } = useWatch();
  const { locale } = Locale.useContainer();
  const countryCode = getCountryCode(locale);
  return (
    <Flex className="flex-d-column ">
      <div>
        <TextField
          name="accountNumber"
          label={t('ACCOUNT_NUMBER')}
          testId="accountNumberTest"
          disabled={readonly || manualBypassEnabled}
          onChange={handleADSInputEvent}
          helpText={t('ACCOUNT_NUMBER_TOOLTIP')}
        />
        {allowManualBypass && <Checkbox name="manualBypassEnabled">{t('PROCEED_WITHOUT_ACCOUNT_NUMBER')}</Checkbox>}
      </div>
      <PhoneField
        name="phoneNumber"
        label={t('PHONE_NUMBER')}
        testId="phoneNumberTest"
        disableExt={true}
        disabled={readonly}
        onChange={handleADSInputEvent}
        defaultCountry={countryCode}
      />
      <TextField
        name="postalCode"
        label={t('POSTAL_CODE')}
        testId="postalCodeTest"
        disabled={readonly}
        onChange={handleADSInputEvent}
      />
    </Flex>
  );
};

export const SingleAmount = ({
  hppToken,
  onSuccess,
  readonly,
  initialLoadingState,
  allowRateLimitBypass,
  allowManualBypass,
}: Props) => {
  const { customerUser } = useAccountManagementStore();
  const { t } = usePaymentsTranslation();
  const { workflowState, clearStateKey, handleADSInputEvent } = useWorkflowState();
  const [remainingAttempts, setRemainingAttempts] = React.useState<number>(3);
  const [loading, setLoading] = React.useState(initialLoadingState);
  const [bypass, setBypass] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const sessionId = datadogLogs.getInternalContext()?.session_id;

  const formSchema = React.useRef<ADSAnyObject>(
    makeSchema(
      {
        ...workflowState,
        accountNumber: workflowState.accountNumber || customerUser?.accountNumber,
      },
      t,
    ),
  );

  const handleSuccess = (data: any, _form: FormMethods<any> | null) => {
    if (!hppToken) {
      return;
    }
    if (data.manualBypassEnabled) {
      onSuccess?.({
        ...data,
        accountNumber: undefined,
        phoneNumber: data.phoneNumber.E164Number,
      });
      return;
    }
    setLoading(true);
    setError(null);
    const nextAttempt = remainingAttempts - 1;
    ApiClient.getInstance()
      .validateEpicAccountCode(hppToken, {
        accountCode: data.accountNumber,
        phoneNumber: data.phoneNumber.E164Number,
        postalCode: data.postalCode,
      })
      .then((result) => {
        if (result.status === 'ok') {
          if (result.data?.status === 'valid') {
            onSuccess?.({
              ...data,
              phoneNumber: data.phoneNumber.E164Number,
              clientId: result.data?.clientId,
            });
          } else {
            const rateLimitExceeded = result.data?.status === 'rate-limit-exceeded' || nextAttempt < 0;
            if (rateLimitExceeded && allowRateLimitBypass) {
              setBypass(true);
            }
            setError(
              rateLimitExceeded
                ? allowRateLimitBypass
                  ? t('ERROR_HPP_VALIDATION_EXCEEDED', undefined, {
                      buttonName: t('NEXT'),
                    } as { buttonName: string })
                  : t('ERROR_HPP_VALIDATION_EXCEEDED_NO_BYPASS')
                : t('ERROR_HPP_VALIDATION'),
            );
          }
        } else {
          console.error('Got unexpected status while validating epic account code: ', result.status);
          if (result.type === 'network') setError(t('ERROR_HPP_VALIDATION_NETWORK'));
          else if (result.status >= 400 && result.status < 500)
            setError(
              t('ERROR_HPP_VALIDATION_UNKNOWN', undefined, {
                id: result.traceId,
              } as any),
            );
          else
            setError(
              t('ERROR_HPP_VALIDATION_INTERNAL', undefined, {
                id: result.traceId,
              } as any),
            );
        }
      })
      .catch((_error) => {
        console.error('Error validating epic account code', _error);
        setError(t('ERROR_HPP_VALIDATION_UNKNOWN', undefined, { id: sessionId } as any));
      })
      .finally(() => {
        setRemainingAttempts(nextAttempt);
        setLoading(false);
      });
  };

  const handleBypass = () => {
    clearStateKey('accountNumber');
    onSuccess?.();
  };

  return (
    <>
      <Form schema={formSchema.current} onSubmit={handleSuccess}>
        <H2 className="mt-100 mb-0">{t('ACCOUNT_INFORMATION')}</H2>
        <p>{t('ENTER_YOUR_ACCOUNT_DETAILS')}</p>
        {error && <InlineAlert type={BrandType.Error}>{error}</InlineAlert>}
        <div className={classNames(classes.form, classes.topForm, 'mt-50')}>
          {loading || !hppToken ? (
            <SkeletonForm />
          ) : (
            <FormFields
              readonly={readonly}
              handleADSInputEvent={handleADSInputEvent}
              allowManualBypass={allowManualBypass}
            />
          )}
        </div>
        <div className="text-right">
          <Button
            submit={!bypass}
            onClick={bypass ? handleBypass : undefined}
            type="primary"
            icon={loading ? 'SpinnerIcon' : undefined}
            disabled={loading}
            testId="nextButton"
          >
            {t('NEXT')}
          </Button>
        </div>
      </Form>
    </>
  );
};
