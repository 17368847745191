import {
  CurrencyCode,
  LocaleCode,
  PaymentMethod,
  toIntlFormat,
  toMinorUnits,
  toUnits,
} from '@appliedsystems/payments-core';
import { AvailablePaymentMethod } from 'src/components/HostedPaymentPageContainer/types';

export const toIntlFormatHelper = (amount: number, currency: CurrencyCode, locale: LocaleCode) => {
  if (!amount) return '-';
  return toIntlFormat({ amount, currencyCode: currency }, locale);
};

export const getAmountWithFees = (
  paymentMethod: AvailablePaymentMethod,
  paymentAmount: number, // minor units
  paymentFee: number, // minor $ units if ACH, minor % (eg 350 = 3.5%) if card
  currencyCode: CurrencyCode,
) => {
  switch (paymentMethod) {
    case PaymentMethod.Ach:
      return {
        paymentFee,
        paymentTotal: paymentAmount + paymentFee,
      };
    case PaymentMethod.Card:
      const paymentSubtotal = toUnits(paymentAmount, currencyCode);
      const calculatedFee = paymentSubtotal * (toUnits(paymentFee, currencyCode) * 0.01);
      const paymentTotal = paymentSubtotal + calculatedFee;
      return {
        paymentFee: toMinorUnits(calculatedFee, currencyCode),
        paymentTotal: toMinorUnits(paymentTotal, currencyCode),
      };
  }
};
