import AdyenCheckout from '@adyen/adyen-web';

import Core from '@adyen/adyen-web/dist/types/core';
import { LocaleCode } from '@appliedsystems/payments-core';
import { useEffect, useMemo, useState } from 'react';
import { ErrorMessage } from 'src/components/ErrorAlert/ErrorAlert';
import { currencyMap } from '../constants/constants';
import { getConfig } from '../util/config';

type Props = {
  locale: LocaleCode;
  total: number;
  handleOnSubmit: (state: AdyenCheckoutState, component: any) => void;
};

export type AdyenCheckoutState = {
  data: {
    riskData: {
      clientData: string;
    };
    paymentMethod: {
      type: string; // ach | scheme
      checkoutAttemptId: string;

      // ach info
      encryptedBankAccountNumber?: string;
      encryptedBankLocationId?: string;
      ownerName?: string;

      // card info
      holderName?: string;
      encryptedCardNumber?: string;
      encryptedExpiryMonth?: string;
      encryptedExpiryYear?: string;
      encryptedSecurityCode?: string;
      brand?: string;
    };
    browserInfo?: {
      acceptHeader: string;
      colorDepth: number;
      language: string;
      javaEnabled: boolean;
      screenHeight: number;
      screenWidth: number;
      userAgent: string;
      timeZoneOffset: number;
    };
    origin?: string;
    clientStateDataIndicator: boolean;
    storePaymentMethod?: boolean;
  };
  isValid: boolean;
};

export type AdyenConfiguration = Core['options'];

export const useAdyenConfig = ({ locale, total, handleOnSubmit }: Props) => {
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>();

  const configuration = useMemo(
    () =>
      ({
        locale,
        translations: {
          en_US: {
            'creditCard.numberField.title': 'Credit Card Number',
            'creditCard.expiryDateField.title': 'Expiration Date',
            'creditCard.cvcField.title': 'Security Code',
          },
          en_CA: {
            'creditCard.numberField.title': 'Credit Card Number',
            'creditCard.expiryDateField.title': 'Expiration Date',
            'creditCard.cvcField.title': 'Security Code',
          },
          fr_CA: {
            'creditCard.numberField.title': 'Numéro de la carte de crédit',
            'creditCard.expiryDateField.title': 'Date d’expiration',
            'creditCard.cvcField.title': 'Code de sécurité',
          },
        },
        environment: getConfig('REACT_APP_ADYEN_ENVIRONMENT'),
        clientKey: getConfig('REACT_APP_ADYEN_CLIENT_KEY'),
        analytics: {
          enabled: true,
        },
        paymentMethodsResponse: {
          paymentMethods: [
            {
              brands: ['visa', 'mc', 'amex', 'discover'],
              name: 'Credit Card',
              type: 'scheme',
            },
            {
              name: 'ACH Direct Debit',
              type: 'ach',
            },
          ],
        },
        showPayButton: true,
        onSubmit: handleOnSubmit,
        onError: (error) => {
          console.error('V2 Adyen onError called', error);
          return;
        },
        amount: {
          value: total,
          currency: currencyMap[locale],
        },
        onAdditionalDetails: () => {}, // what should this do?
        paymentMethodsConfiguration: {
          storedCard: {
            amount: undefined,
          },
          card: {
            hasHolderName: false,
            holderNameRequired: false,
            amount: {
              value: total,
              currency: currencyMap[locale],
            },
            billingAddressRequired: false,
          },
          ach: {
            hasHolderName: true,
            holderNameRequired: false,
            // Keep this ts-ignore as billingAddressRequired is not in the adyen types
            // It's working as expected and billing address is not required
            // @ts-ignore
            billingAddressRequired: false,
          },
        },
      } satisfies AdyenConfiguration),
    [handleOnSubmit, total, locale],
  );

  const [checkout, setCheckout] = useState<Core>();

  useEffect(() => {
    (async function updateCheckout() {
      try {
        if (!checkout) setCheckout(await AdyenCheckout(configuration));
        else await checkout.update(configuration);
        setErrorMessage(undefined);
      } catch (err: unknown) {
        console.error('Error creating Adyen Checkout', err);
        setErrorMessage(['INIT_ADYEN_CHECKOUT_FAILED']);
      }
    })();
  }, [checkout, configuration]);

  return { configuration, checkout, errorMessage };
};
