import { Col, FieldSkeleton, Flex, Row } from '@appliedsystems/applied-design-system';
import React from 'react';

export const SkeletonForm = () => (
  <>
    <Flex>
      <Row>
        <Col xs={12}>
          <FieldSkeleton />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <FieldSkeleton />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <FieldSkeleton />
        </Col>
      </Row>
      <Row>
        <Col xs={8}>
          <FieldSkeleton />
        </Col>
        <Col xs={4}>
          <FieldSkeleton />
        </Col>
      </Row>
    </Flex>
  </>
);
