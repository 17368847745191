import { toIntlFormat } from '@appliedsystems/payments-core';
import React, { useCallback } from 'react';
import { currencyMap } from '../../constants/constants';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { Locale } from '../../store/Locale';
import { FullInvoiceGroup } from '../HostedPaymentPageContainer/types';
import { useHppDataStore } from '../HostedPaymentPageContainer/useHppData';
import classes from './AmountBreakdown.module.scss';

export const AmountBreakdown = (): React.ReactElement => {
  const { t } = usePaymentsTranslation();
  const { hppData, selectedInvoices } = useHppDataStore();
  const { locale } = Locale.useContainer();
  const currencyCode = currencyMap[locale];

  const getIntlFormattedAmount = (amount: number) =>
    toIntlFormat(
      {
        amount,
        currencyCode,
      },
      locale,
    );

  const isFinanceable = useCallback(
    (invoice: FullInvoiceGroup) => {
      return hppData.pf?.financeableInvoices.some(
        (financeableInvoice) => financeableInvoice.invoiceNumber === invoice.invoiceNumber,
      );
    },
    [hppData.pf?.financeableInvoices],
  );

  const downPayment = getIntlFormattedAmount(hppData.pf?.quoteId ? hppData.pf?.downPaymentAmount : 0);
  const fee = hppData.paymentMethod ? getIntlFormattedAmount(hppData.paymentFee) : <span>{t('TBD')}</span>;
  const totalDue = hppData.paymentMethod
    ? getIntlFormattedAmount(hppData.paymentTotal)
    : getIntlFormattedAmount(hppData.paymentAmount);
  const nonFinancedAmount = hppData.paymentTotal - (hppData.pf?.quoteId ? hppData.pf?.amountFinanced : 0);

  const hasDueInFullInvoices = selectedInvoices.some((invoice) => !isFinanceable(invoice));

  return (
    <>
      <div>
        {t('BREAKDOWN_DESCRIPTION', undefined, {
          totalAmount: totalDue,
        } as any)}
      </div>
      <div className={classes.table}>
        <div className={classes.row}>
          <div className={classes.cell}>
            <strong>{t('DOWNPAYMENT_DESCRIPTION')}</strong>
          </div>
          {hasDueInFullInvoices && (
            <div className={classes.cell}>
              <strong>{t('NON_FINANCED_AMOUNT')}</strong>
            </div>
          )}
          <div className={classes.cell}>
            <strong>{t('CONVENIENCE_FEE')}</strong>
          </div>
          <div className={classes.cell}>
            <strong>{t('TOTAL_DUE_TODAY')}</strong>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.cell}>{downPayment}</div>
          {hasDueInFullInvoices && <div className={classes.cell}>{nonFinancedAmount}</div>}
          <div className={classes.cell}>{fee}</div>
          <div className={classes.cell}>{totalDue}</div>
        </div>
      </div>
    </>
  );
};
