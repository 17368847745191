import { Col, CurrencyField, Row, TextField, useFormContext } from '@appliedsystems/applied-design-system';
import { toMinorUnits } from '@appliedsystems/payments-core';
import React, { useEffect } from 'react';
import { currencyMap } from '../../constants/constants';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { Locale } from '../../store/Locale';
import { checkArrayValueEquality } from '../../util/util';
import { InvoiceDataGrid, InvoiceGroup } from '../HostedPaymentPage/workflows/InvoiceDataGrid';
import { PayBySelection } from '../HostedPaymentPageContainer/enums';
import { FullInvoiceGroup, SelectedInvoice } from '../HostedPaymentPageContainer/types';
import { useHppDataStore } from '../HostedPaymentPageContainer/useHppData';
import { PolicyFormSchema } from './PolicyInformationForm';

export const PolicyFormFields = ({ onDataChange }: { onDataChange: () => void }) => {
  const { locale } = Locale.useContainer();
  const { hppData, setHppData, retrievedInvoices, selectedInvoices, setSelectedInvoices } = useHppDataStore();
  const { t } = usePaymentsTranslation();
  const { payBy } = hppData;
  const { watch, setValue, getValues } = useFormContext<PolicyFormSchema>();

  useEffect(() => {
    // Unfortunately this housecleaning is needed due to the fact
    // that we're updating hppData in real time
    if (getValues('payBy') === PayBySelection.INVOICE) {
      setValue('paymentAmount', undefined as any, {
        shouldValidate: false,
        shouldTouch: false,
        shouldDirty: false,
      });
      setValue('paymentDescription', '', {
        shouldValidate: false,
        shouldTouch: false,
        shouldDirty: false,
      });
    }

    const subscription = watch((values, { name, type }) => {
      if (type === 'change') {
        const updateValue: typeof values = {
          paymentDescription: values.paymentDescription,
          invoiceNumber: values.invoiceNumber,
          policyNumber: values.policyNumber,
        };

        if (values.paymentAmount) updateValue.paymentAmount = toMinorUnits(values.paymentAmount, currencyMap[locale]);

        setHppData(updateValue);
        onDataChange();
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, onDataChange, locale, setHppData, getValues, setValue]);

  const handleInvoiceChange = (invoices: InvoiceGroup[]) => {
    const incomingIds = invoices.map((invoice) => invoice.invoiceNumber);
    const currentIds = selectedInvoices.map((invoice) => invoice.invoiceNumber);

    // Array equality is referential, eg. a[1,2] !== b[1,2] but a[1] === b[1]
    // so we need to check for the actual values inside the variable.
    // ADS' data grid 'toggleChecked' function will trigger 'onChange' by default
    // which means that if we don't have this check in place we'll try to update
    // our hppData unecessarily, triggering a form revalidation that gets us stuck
    // in this step and unable to proceed to the next.
    if (checkArrayValueEquality(incomingIds, currentIds)) {
      return;
    }

    const nextSelectedInvoices: SelectedInvoice[] = [];
    const fullInvoiceGroups: FullInvoiceGroup[] = [];

    invoices.forEach((invoice) => {
      const lineItems = retrievedInvoices.filter((i) => i.invoiceNumber === invoice.invoiceNumber);

      if (lineItems.length) {
        const currentSelectedInvoices = lineItems.map((lineItem) => ({
          invoiceNumber: lineItem.invoiceNumber,
          amountDue: +lineItem.amountDue.units * 100 + lineItem.amountDue.partialUnits,
          description: lineItem.description,
          dueDate: lineItem.dueDate,
          itemNumber: lineItem.itemNumber,
        }));

        nextSelectedInvoices.push(...currentSelectedInvoices);
        fullInvoiceGroups.push({
          ...invoice,
          invoiceItems: currentSelectedInvoices,
        });
      }
    });

    // amountDueMinorUnits is the whole amount (payment with two decimal places multiplied by 100)
    const paymentAmount = nextSelectedInvoices.reduce((total, invoice) => total + invoice.amountDue, 0);

    // paymentDescription = Payment against invoice(s): [x, y, z].
    const paymentDescription = `${t('PAYMENT_AGAINST_INVOICE')}: ${fullInvoiceGroups
      .map((e) => e.invoiceNumber)
      .join(', ')}.`;

    setSelectedInvoices(fullInvoiceGroups);
    setHppData({ paymentAmount, paymentDescription });
    onDataChange();
  };

  return (
    <>
      {payBy !== PayBySelection.INVOICE && (
        // Amount or None
        <>
          <Row>
            <Col xs={12} md={12} xl={2}>
              <CurrencyField
                name="paymentAmount"
                locale={locale}
                maximumDecimals={2}
                maxLength={12}
                testId="paymentAmount"
              />
            </Col>
            <Col xs={12} md={12} xl={4}>
              <TextField name="paymentDescription" />
            </Col>
            <Col xs={12} md={6} xl={3}>
              <TextField name="policyNumber" />
            </Col>
            <Col xs={12} md={6} xl={3}>
              <TextField name="invoiceNumber" />
            </Col>
          </Row>
        </>
      )}
      {
        // Pay by Invoice (Single invoice is handled in the return call already)
        payBy === PayBySelection.INVOICE && (
          <>
            <Row>
              <Col className="mb-150" xs={12}>
                {t('SELECT_INVOICES_TO_PAY')}
              </Col>
            </Row>
            <InvoiceDataGrid
              invoices={retrievedInvoices}
              onChange={(invoices) => handleInvoiceChange(invoices)}
              locale={locale}
              preSelectedInvoices={selectedInvoices.map((invoice) => invoice.invoiceNumber)}
            ></InvoiceDataGrid>
          </>
        )
      }
    </>
  );
};
