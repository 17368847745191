import { useCallback, useMemo, useState } from 'react';
import { createContainer } from 'unstated-next';
import { ApiClient } from '../api/ApiClient';

const useModalWindow = (initialState = false) => {
  const [isOpen, setIsOpen] = useState(initialState);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);
  return useMemo(() => ({ isOpen, open, close }), [isOpen, open, close]);
};

export type CustomerUserData = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  accountNumber: string | null;
};

const useAccountManagement = () => {
  const [customerUser, setCustomerUser] = useState<CustomerUserData | null>(null);
  const loginModal = useModalWindow();
  const createAccountModal = useModalWindow();
  const manageAccountWindow = useModalWindow();

  const login = useCallback(
    async (
      paymentSessionToken: string,
      payload: {
        email: string;
        verificationCode: string;
      },
    ) => {
      const { email, verificationCode } = payload;
      const response = await ApiClient.getInstance(paymentSessionToken).loginCustomerUserAccount({
        email,
        verificationCode,
      });

      if (response.status === 'ok' && response.data) {
        localStorage.setItem('customerUserAccessToken', response.data.accessToken);
        setCustomerUser(response.data.user);
      }

      return response;
    },
    [],
  );

  const requestVerificationCode = useCallback(
    async (paymentSessionToken: string, email: string, onSuccess: () => void) => {
      try {
        const response = await ApiClient.getInstance(paymentSessionToken).requestCustomerUserVerificationCode({
          email,
        });

        if (response.status !== 'ok') {
          console.error('Unexpected response status', response.status);
          throw new Error('Unexpected response status');
        }

        onSuccess();
      } catch (error) {
        console.error('Failed to request verification code', error);
      }
    },
    [],
  );

  const logout = useCallback(() => {
    setCustomerUser(null);
    localStorage.removeItem('customerUserAccessToken');
  }, []);

  const getCurrentCustomerUser = useCallback(async (paymentSessionToken: string) => {
    const response = await ApiClient.getInstance(paymentSessionToken).getCurrentCustomerUser();
    if (response.status === 'ok' && response.data) {
      setCustomerUser(response.data);
    }

    return response;
  }, []);

  const { id, firstName, accountNumber, email, lastName } = customerUser || {};

  // Memoize the customer user object to avoid unnecessary re-renders
  const customerUserMemoized = useMemo(() => {
    if (!id) {
      return null;
    }

    return {
      id,
      firstName,
      accountNumber,
      email,
      lastName,
    };
  }, [id, firstName, accountNumber, email, lastName]);

  return {
    loginModal,
    createAccountModal,
    manageAccountWindow,

    customerUser: customerUserMemoized,

    requestVerificationCode,
    login,
    logout,
    getCurrentCustomerUser,
  };
};

export const AccountManagement = createContainer(useAccountManagement);

export const useAccountManagementStore = () => {
  return AccountManagement.useContainer();
};
