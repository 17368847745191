import { format, utcToZonedTime } from 'date-fns-tz';

export const toLocaleDateTimeString = (date: string | Date | null | undefined, defaultValue?: string) => {
  if (!date) {
    return defaultValue;
  }
  const _date = new Date(date);
  const localTime = utcToZonedTime(_date, Intl.DateTimeFormat().resolvedOptions().timeZone);
  const localTimeString = format(localTime, 'P p zzz');
  return localTimeString;
};

export const toLocaleDateString = (date: string | Date | null | undefined, defaultValue?: string) => {
  if (!date) {
    return defaultValue;
  }
  const _date = new Date(date);
  const localTime = utcToZonedTime(_date, Intl.DateTimeFormat().resolvedOptions().timeZone);
  return format(localTime, 'P');
};
