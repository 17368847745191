import { invalidHppSubdomains, LocaleCode } from '@appliedsystems/payments-core';
import psl from 'psl';
import tinycolor from 'tinycolor2';

// gets the first subdomain from the current URL, or null if there is none
export const getSubdomain = (): string | null => {
  const hostname = psl.parse(window.location.hostname);
  let subdomain = !hostname.error ? hostname.subdomain?.split('.')[0] : null;
  if (subdomain && invalidHppSubdomains.includes(subdomain)) subdomain = null;
  return subdomain ?? null;
};

export const invertColor = (hex: string): string =>
  '#' + (0xffffff - parseInt(hex.replace('#', ''), 16)).toString(16).padStart(6, '0');

export const convertMinutesToDays = (minutes: number): number => {
  return Math.round(minutes / (60 * 24));
};

export const getLanguageCode = (locale: LocaleCode): string => {
  const languageCode = locale.split('-')[0];
  return languageCode || 'en';
};

export const adjustTextColorForReadability = (
  textColor: string,
  backgroundColor: string,
  maxAttempts: number = 25, // max number of attempts to adjust the color to prevent infinite loop
) => {
  let adjustedColor = textColor;
  let isReadable = tinycolor.isReadable(textColor, backgroundColor, {
    level: 'AA',
    size: 'large',
  });
  let attempts = 0;
  while (!isReadable && attempts < maxAttempts) {
    if (tinycolor(backgroundColor).isDark()) {
      adjustedColor = tinycolor(adjustedColor).lighten(10).toString();
    } else {
      adjustedColor = tinycolor(adjustedColor).darken(10).toString();
    }

    isReadable = tinycolor.isReadable(adjustedColor, backgroundColor, {
      level: 'AA',
      size: 'large',
    });
    attempts++;
  }
  return adjustedColor;
};

export const checkArrayValueEquality = <T>(a: T[], b: T[]) =>
  a.length === b.length && a.every((entry, index) => entry === b[index]);
