import { LocaleCode } from '@appliedsystems/payments-core';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { createContainer } from 'unstated-next';
import { getLocale } from '../localization/translations';

const useLocale = (initialState = LocaleCode.en_US) => {
  const [searchParams] = useSearchParams();

  const [pseudoLocalization, setPseudoLocalization] = React.useState(false);
  const [locale, setLocale] = React.useState(() =>
    getLocale(searchParams.get('locale') || window.localStorage.getItem('locale'), initialState),
  );

  useEffect(() => {
    if (!locale) {
      setLocale(initialState);
      return;
    }
    window.localStorage.setItem('locale', locale);
    // eslint-disable-next-line
  }, [locale]);

  return { locale, setLocale, pseudoLocalization, setPseudoLocalization };
};

export const Locale = createContainer(useLocale);
