import React from 'react';
import { getConfig } from '../../util/config';
import styles from './Logo.module.scss';

type Props = {
  src?: string;
  imageId?: string;
  alt?: string;
};

export const Logo = ({ src, imageId, alt }: Props) => {
  if (!src && !imageId) {
    return null;
  }

  let logoUrl = src;
  if (!logoUrl) {
    logoUrl = `${getConfig('REACT_APP_PAYMENTS_API_BASE_URL')}/image/${imageId}`;
  }

  return (
    <div className={styles.logoWrapper}>
      <img src={logoUrl} alt={alt} />
    </div>
  );
};
