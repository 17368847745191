import { Flex } from '@appliedsystems/applied-design-system';
import React from 'react';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { useAgencyDetailsStore } from '../../store/AgencyDetail';
import classes from './Disclaimer.module.scss';

export const Disclaimer = () => {
  const { t } = usePaymentsTranslation();
  const { data: agencyDetails } = useAgencyDetailsStore();
  return (
    <Flex>
      <p className={classes.disclaimer}>
        <sub> {t('CONVENIENCE_FEE_DISCLAIMER').replace('{Company_Name}', agencyDetails?.name || '')}</sub>
      </p>
    </Flex>
  );
};
