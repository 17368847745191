import { Button, Col, Container, Flex, H1, Row, Tab, TabGroup } from '@appliedsystems/applied-design-system';
import { LocaleCode } from '@appliedsystems/payments-core';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { useAccountManagementStore } from '../../store/AccountManagement';
import { Logo } from '../Logo';
import styles from './ManageAccount.module.scss';
import { PaymentHistory } from './PaymentHistory';
import { PaymentMethods } from './PaymentMethods';

type Props = {
  brand?: {
    logoUrl?: string;
    name?: string;
  };
  // TODO: Add useLocale store hook to get locale
  locale?: LocaleCode;
  paymentSessionToken: string;
};

export const ManageAccount = ({ brand, locale, paymentSessionToken }: Props) => {
  const { manageAccountWindow, customerUser } = useAccountManagementStore();
  const { t } = usePaymentsTranslation();

  useEffect(() => {
    if (!manageAccountWindow.isOpen) {
      return;
    }

    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        manageAccountWindow.close();
      }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [manageAccountWindow]);

  if (!customerUser && manageAccountWindow.isOpen) {
    console.warn('Customer user not found');
    manageAccountWindow.close();
    return null;
  }

  return (
    <Container className={classNames(styles.container, manageAccountWindow.isOpen && styles.open)}>
      <Row>
        <Col xs={0} md={1} lg={2}></Col>
        <Col xs={12} md={10} lg={8}>
          <Logo src={brand?.logoUrl} alt={brand?.name} />
          <Flex>
            <Row className={`${styles.topLine} mt-100 mb-200`}>
              <Col xs={12} md={3}>
                <Button type="link" onClick={manageAccountWindow.close}>
                  {t('ACCOUNT_SETTINGS_BACK')}
                </Button>
              </Col>
              <Col xs={12} md={6} className="text-center">
                <H1>{t('ACCOUNT_SETTINGS_MANAGE_ACCOUNT')}</H1>
              </Col>
              <Col xs={3} />
            </Row>
          </Flex>

          {manageAccountWindow.isOpen && (
            <TabGroup headersClassName="mb-100">
              <Tab title={t('ACCOUNT_SETTINGS_TAB_TITLE_PAYMENT_METHODS')} tabKey="payment-methods">
                <PaymentMethods paymentSessionToken={paymentSessionToken} />
              </Tab>
              <Tab title={t('ACCOUNT_SETTINGS_TAB_TITLE_PAYMENT_HISTORY')} tabKey="payment-history">
                <PaymentHistory locale={locale} />
              </Tab>
            </TabGroup>
          )}
        </Col>
      </Row>
    </Container>
  );
};
