import '@adyen/adyen-web/dist/adyen.css';
import { Theme } from '@appliedsystems/applied-design-system';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { translations } from '../../localization/translations';
import metaJson from '../../meta.json';
import { AccountManagement } from '../../store/AccountManagement';
import { AgencyDetailsStore } from '../../store/AgencyDetail';
import { BrandTheme } from '../../store/BrandTheme';
import { Locale } from '../../store/Locale';
import styles from '../../styles/_common.scss';
import { getSubdomain } from '../../util/util';
import { Cancel } from '../Cancel/Cancel';
import { Checkout } from '../Checkout/Checkout';
import { CheckoutContainer } from '../CheckoutV2/CheckoutContainer';
import { CheckoutStore } from '../CheckoutV2/useCheckoutStore';
import { ClientVersionChecker } from '../ClientVersionChecker';
import { Error } from '../Error/Error';
import { HostedPaymentPage } from '../HostedPaymentPage/HostedPaymentPage';
import { HostedPaymentContainer } from '../HostedPaymentPageContainer/HostedPaymentPageContainer';
import { HppDataStore } from '../HostedPaymentPageContainer/useHppData';
import { HppSessionStore } from '../HostedPaymentPageContainer/useHppSession';

const queryClient = new QueryClient();

const HppSubdomainRedirect = () => {
  if (getSubdomain()) return <Navigate to={'/flow/hpp'} />;
  return null;
};

const cachedVersion = metaJson?.version;

export const App = () => {
  const App = (): React.ReactElement => {
    const { locale, pseudoLocalization } = Locale.useContainer();

    return (
      <Theme locale={locale} translations={translations} pseudolocalization={pseudoLocalization}>
        <style>{String(styles)}</style>
        <ClientVersionChecker cachedVersion={cachedVersion} locale={locale} />
        <main>
          <Routes>
            <Route path="/flow/checkout" element={<Checkout />} />
            <Route path="/flow/checkoutv2" element={<CheckoutContainer />} />
            <Route path="/flow/hpp" element={<HostedPaymentPage />} />
            <Route path="/flow/hppv2" element={<HostedPaymentContainer />} />
            <Route path="/flow/error" element={<Error />} />
            <Route path="/flow/cancel" element={<Cancel />} />
            <Route path="/" element={<HppSubdomainRedirect />} />
          </Routes>
        </main>
      </Theme>
    );
  };

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename="/">
        <Locale.Provider>
          <BrandTheme.Provider>
            <HppDataStore.Provider>
              <HppSessionStore.Provider>
                <CheckoutStore.Provider>
                  <AccountManagement.Provider>
                    <AgencyDetailsStore.Provider>
                      <App />
                    </AgencyDetailsStore.Provider>
                  </AccountManagement.Provider>
                </CheckoutStore.Provider>
              </HppSessionStore.Provider>
            </HppDataStore.Provider>
          </BrandTheme.Provider>
        </Locale.Provider>
      </BrowserRouter>
    </QueryClientProvider>
  );
};
