import DropinElement from '@adyen/adyen-web/dist/types/components/Dropin';
import { BrandType, useNotifications } from '@appliedsystems/applied-design-system';
import {
  ErrorCode,
  HostedPaymentSessionPayload,
  HostedPaymentSessionRequest,
  MerchantAccountSettingsPayload,
} from '@appliedsystems/payments-core';
import { datadogLogs } from '@datadog/browser-logs';
import React from 'react';
import { ApiClient } from '../api/ApiClient';
import { usePaymentsTranslation } from '../hooks/usePaymentsTranslation';
import { ErrorDetail } from '../types/common';
import { AdyenConfiguration, generateIdempotencyKey, initAdyenCheckout } from '../util/adyen';
import { getSubdomain } from '../util/util';

type Action =
  | {
      type: 'getAgencyDetail';
    }
  | {
      type: 'getAgencyDetailSuccess';
      payload: Pick<State, 'agencyDetail'>;
    }
  | {
      type: 'getHppSession';
    }
  | {
      type: 'getHppSessionSuccess';
      payload: Pick<State, 'hppSessionDetail' | 'hppSessionRequest'>;
    }
  | {
      type: 'initPayForm';
    }
  | {
      type: 'initPayFormSuccess';
      payload: Pick<State, 'dropinElement'>;
    }
  | {
      type: 'updatePaymentMethod';
      payload: Pick<State, 'paymentMethod'>;
    }
  | {
      type: 'editPaymentInfo';
    }
  | {
      type: 'recaptchaReady';
    }
  | {
      type: 'makePayment';
    }
  | {
      type: 'makePaymentSuccess';
    }
  | {
      type: 'error';
      payload: {
        errorCode: ErrorCode;
        errorDetail?: ErrorDetail | null;
      };
    };

type State = {
  hppToken: string | null;
  isHppLoading: boolean;
  isDropinLoading: boolean;
  isPaymentSuccess: boolean;
  agencyDetail: MerchantAccountSettingsPayload | null;
  hppSessionRequest: Partial<HostedPaymentSessionRequest> | null;
  hppSessionDetail: HostedPaymentSessionPayload | null;
  paymentMethod: 'card' | 'ach' | null;
  dropinElement: DropinElement | null;
  hasFatalError: boolean;
  errorCode: ErrorCode | null;
  errorDetail: ErrorDetail | null;
};

const initialState: State = {
  hppToken: null,
  isHppLoading: false,
  isDropinLoading: false,
  isPaymentSuccess: false,
  agencyDetail: null,
  hppSessionRequest: null,
  hppSessionDetail: null,
  paymentMethod: null,
  dropinElement: null,
  hasFatalError: false,
  errorCode: null,
  errorDetail: null,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'getAgencyDetail':
      return {
        ...state,
        agencyDetail: null,
        isHppLoading: true,
        isDropinLoading: false,
        hppSessionDetail: null,
        hasFatalError: false,
        errorCode: null,
      };
    case 'getAgencyDetailSuccess':
      return {
        ...state,
        ...action.payload,
        hppToken: action.payload.agencyDetail?.token ?? null,
        isHppLoading: false,
        isDropinLoading: false,
        hppSessionDetail: null,
        hasFatalError: false,
        errorCode: null,
      };
    case 'getHppSession':
      return {
        ...state,
        isHppLoading: false,
        isDropinLoading: true,
        hppSessionDetail: null,
        hasFatalError: false,
        errorCode: null,
      };
    case 'getHppSessionSuccess':
      return {
        ...state,
        ...action.payload,
        isHppLoading: false,
        isDropinLoading: true,
        hasFatalError: false,
        errorCode: null,
      };
    case 'initPayForm':
      return {
        ...state,
        isHppLoading: false,
        isDropinLoading: true,
        hasFatalError: false,
        errorCode: null,
      };
    case 'initPayFormSuccess':
      return {
        ...state,
        ...action.payload,
        isHppLoading: false,
        isDropinLoading: false,
        hasFatalError: false,
        errorCode: null,
      };
    case 'makePayment':
      return {
        ...state,
        isHppLoading: false,
        isDropinLoading: false,
        hasFatalError: false,
        errorCode: null,
      };
    case 'makePaymentSuccess':
      return {
        ...state,
        isHppLoading: false,
        isDropinLoading: false,
        isPaymentSuccess: true,
        hasFatalError: false,
        errorCode: null,
      };
    // file deepcode ignore DuplicateCaseBody: <please specify a reason of ignoring this>
    case 'updatePaymentMethod':
      return {
        ...state,
        ...action.payload,
        isHppLoading: false,
        isDropinLoading: false,
        hasFatalError: false,
        errorCode: null,
      };
    case 'editPaymentInfo':
      return {
        ...state,
        isHppLoading: false,
        isDropinLoading: false,
        hppSessionRequest: null,
        hppSessionDetail: null,
        paymentMethod: null,
        dropinElement: null,
        hasFatalError: false,
        errorCode: null,
      };
    case 'error':
      return {
        ...state,
        ...action.payload,
        isHppLoading: false,
        isDropinLoading: false,
        hasFatalError: true,
      };
    default:
      throw new Error();
  }
};

export type HppState = State & {
  getAgencyDetail: () => void;
  getHppSession: (data: Partial<HostedPaymentSessionRequest>) => void;
  reloadHppSession: () => void;
  initPayForm: (domNode: string, config: Partial<AdyenConfiguration>) => void;
  updatePaymentMethod: (paymentMethod: 'card' | 'ach' | null) => void;
  makePayment: (
    paymentMethod: any,
    component: DropinElement,
    recaptchaToken: string,
    storePaymentMethod?: boolean,
  ) => Promise<false | undefined>;
  editPaymentInfo: () => void;
  setComponentStatus: (status: 'success' | 'error' | 'loading' | 'ready', component: DropinElement) => void;
  setFatalError: (errorCode: ErrorCode, errorDetail?: ErrorDetail | null) => void;
};

export const useHppReducer = (hppToken: string | null): HppState => {
  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState,
    hppToken,
  });

  const { addNotification } = useNotifications();
  const { t } = usePaymentsTranslation();

  const setFatalError = (errorCode: ErrorCode, errorDetail?: ErrorDetail | null) =>
    dispatch({ type: 'error', payload: { errorCode, errorDetail } });
  const showTemporaryErrorMessage = (message: string, component?: DropinElement) => {
    component?.setStatus('error');
    addNotification({
      type: BrandType.Error,
      title: t('ERROR_PAYMENT_ERROR'),
      time: '',
      content: message,
    });
    setTimeout(() => {
      component?.setStatus('ready');
    }, 2000);
  };
  const showUnknownErrorMessage = (code: number, component?: DropinElement) => {
    showTemporaryErrorMessage(`Unexpected Error (code: h${code})`);
  };

  const getAgencyDetail = () => {
    const subdomain = getSubdomain();

    if (!state.hppToken && !subdomain) {
      console.error('Retrieval of Agency Details failed: HPP Token and subdomain are missing');
      setFatalError(ErrorCode.AgencyDetailsMissing);
      return;
    }
    dispatch({ type: 'getAgencyDetail' });
    ApiClient.getInstance()
      .getHppAgencyDetail(state.hppToken, subdomain)
      .then((response) => {
        if (response.status !== 'ok') {
          const errorCode = response.additionalDetails?.length ? response.additionalDetails[0].errorCode : null;
          console.error('Failed to get agency details with response', response);
          setFatalError(
            errorCode ||
              (response.type === 'network'
                ? ErrorCode.FetchAgencyDetailFailedNetwork
                : response.status >= 400 && response.status < 500
                ? ErrorCode.FetchAgencyDetailFailedUnknown
                : ErrorCode.FetchAgencyDetailFailedInternal),
            `${state.hppToken} - ${subdomain}`,
          );
          return;
        }
        if (!response.data) {
          console.error('Failed to fetch agency details: No data in response');
          setFatalError(ErrorCode.FetchAgencyEmptyResponse, `${state.hppToken} - ${subdomain}`);
          return;
        }
        dispatch({
          type: 'getAgencyDetailSuccess',
          payload: { agencyDetail: response.data },
        });
      })
      .catch((err) => {
        console.error('Fialed to fetch agency details with error', err);
        setFatalError(ErrorCode.FetchAgencyFailed, `${state.hppToken} - ${subdomain}`);
      });
  };

  const previousHppSessionRequest = React.useRef<Partial<HostedPaymentSessionRequest>>();
  const getHppSession = (data: Partial<HostedPaymentSessionRequest>) => {
    if (!state.hppToken) {
      console.error('Get HPP session failed: token missing');
      setFatalError(ErrorCode.GetHPPSessionFailedTokenMissing);
      return;
    }
    previousHppSessionRequest.current = data;
    dispatch({ type: 'getHppSession' });
    ApiClient.getInstance()
      .initHppSession(state.hppToken, data)
      .then((response) => {
        if (response.status !== 'ok') {
          const errorCode = response.additionalDetails?.length ? response.additionalDetails[0].errorCode : null;
          console.error('Failed to get HPP session with response', response);
          setFatalError(
            errorCode ||
              (response.type === 'network'
                ? ErrorCode.GetHPPSessionFailedNetwork
                : response.status >= 400 && response.status < 500
                ? ErrorCode.GetHPPSessionFailedUnknown
                : ErrorCode.GetHPPSessionFailedInternal),
            state.hppToken,
          );
          return;
        }
        if (!response.data) {
          console.error('Failed to get HPP session: No data in response');
          setFatalError(ErrorCode.GetHPPSessionEmptyResponse, state.hppToken);
          return;
        }
        dispatch({
          type: 'getHppSessionSuccess',
          payload: {
            hppSessionDetail: response.data,
            hppSessionRequest: data,
          },
        });
      })
      .catch((err) => {
        console.error('Failed to get HPP session with error', err);
        setFatalError(ErrorCode.GetHPPSessionErrorUnknown, state.hppToken);
      });
  };

  const reloadHppSession = () => {
    if (!previousHppSessionRequest.current) {
      return;
    }

    getHppSession(previousHppSessionRequest.current);
  };

  const initPayForm = (domNode: string, config: Partial<AdyenConfiguration>) => {
    if (!state.hppSessionDetail) {
      return;
    }

    if (state.dropinElement) {
      state.dropinElement.unmount();
    }

    initAdyenCheckout(domNode, state.hppSessionDetail, t, config)
      .then((component) => {
        dispatch({
          type: 'initPayFormSuccess',
          payload: { dropinElement: component },
        });
      })
      .catch((err) => {
        console.error('Error initializing Adyen checkout', err);
        setFatalError(ErrorCode.InitAdyenCheckoutFailed, state.hppToken);
      });
  };

  const makePayment = async (
    paymentMethod: any,
    component: DropinElement,
    recaptchaToken: string,
    storePaymentMethod?: boolean,
  ) => {
    dispatch({
      type: 'makePayment',
    });

    if (!state.hppSessionDetail?.paymentFlowSessionId || !state.hppSessionDetail?.pspSessionId) {
      setFatalError(ErrorCode.PaymentFlowSessionIdMissing);
      return false;
    }

    const idempotencyKey = generateIdempotencyKey();
    datadogLogs.logger.info('PSP session and idemPotency key for request', {
      pspSessionId: state.hppSessionDetail.sessionId,
      idempotencyKey: idempotencyKey,
    });

    try {
      const response = await ApiClient.getInstance(state.hppSessionDetail.paymentFlowSessionId).completePayment({
        pspSessionId: state.hppSessionDetail?.sessionId,
        idempotencyKey,
        paymentMethod,
        recaptchaToken,
        storePaymentMethod,
      });

      if (response.status !== 'ok') {
        const errorCode = response.additionalDetails?.length && response.additionalDetails[0].errorCode;
        if (errorCode === ErrorCode.PaymentAmountTooLow) {
          showTemporaryErrorMessage(t('ERROR_FEE_AMOUNT_BELOW_MINIMUM'), component);
        } else if (errorCode === ErrorCode.ACHValidationFailed) {
          showTemporaryErrorMessage(t('ERROR_ACH_VALIDATION_FAILED'), component);
        } else if (errorCode === ErrorCode.BankAccountNotValid) {
          showTemporaryErrorMessage(t('ERROR_BANK_ACCOUNT_NOT_VALID'), component);
        } else if (errorCode === ErrorCode.DuplicateCompletePaymentAttempt) {
          showTemporaryErrorMessage(t('ERROR_DUPLICATE_PAYMENT_ATTEMPT'), component);
        } else if (errorCode === ErrorCode.RecaptchaBrowserError) {
          showTemporaryErrorMessage(t('RECAPTCHA_BROWSER_ERROR'), component);
        } else {
          console.error('Complete Payment Error Not OK (HPP)', response);
          showUnknownErrorMessage(1, component);
        }
        return;
      }

      if (!response.data) {
        console.error('Complete Payment Error No Data (HPP)', response);
        showUnknownErrorMessage(2, component);
        return;
      }

      if (['Authorised'].includes(response.data.resultCode)) {
        component.setStatus('success');
        dispatch({
          type: 'makePaymentSuccess',
        });
        return;
      }

      if (['Error'].includes(response.data.resultCode)) {
        console.error('Complete Payment Error Result (HPP)', response);
        showUnknownErrorMessage(3, component);
        return;
      }

      if (['Refused'].includes(response.data.resultCode)) {
        console.error('Complete Payment Error refused (HPP)', response);
        if (response.data.refusalReason) showTemporaryErrorMessage(response.data.refusalReason, component);
        else showUnknownErrorMessage(4, component);
        return;
      }

      component.setStatus('ready');
    } catch (err: any) {
      console.error('Complete Payment Error unknown (HPP)', err);
      showUnknownErrorMessage(5, component);
    }
  };

  const editPaymentInfo = () => {
    state.dropinElement?.unmount();
    dispatch({
      type: 'editPaymentInfo',
    });
  };

  const setComponentStatus = (status: 'success' | 'error' | 'loading' | 'ready', component: DropinElement) => {
    component.setStatus(status);
  };

  const updatePaymentMethod = (paymentMethod: 'card' | 'ach' | null) => {
    dispatch({
      type: 'updatePaymentMethod',
      payload: { paymentMethod },
    });
  };

  return {
    ...state,
    getAgencyDetail,
    getHppSession,
    reloadHppSession,
    initPayForm,
    updatePaymentMethod,
    makePayment,
    editPaymentInfo,
    setComponentStatus,
    setFatalError,
  };
};
