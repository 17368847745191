import { PaymentMethod, PspSessionResponsePayload } from '@appliedsystems/payments-core';
import { Buffer } from 'buffer';
import { HppData } from '../HostedPaymentPageContainer/types';

export const parseRedirectUrl = ({
  returnUrl,
  hppData,
  sessionDetails,
}: {
  returnUrl: NonNullable<PspSessionResponsePayload['returnUrl']>;
  hppData: Pick<HppData, 'paymentMethodBrand' | 'paymentMethod'>;
  sessionDetails: Pick<
    PspSessionResponsePayload,
    'feeCredit' | 'feeAch' | 'amount' | 'currency' | 'referenceId' | 'referenceData' | 'paymentAttributes'
  >;
}) => {
  let fee = '';

  if (hppData.paymentMethod === PaymentMethod.Card) {
    fee = sessionDetails.feeCredit?.toString() || '';
  }
  if (hppData.paymentMethod === PaymentMethod.Ach) {
    fee = sessionDetails.feeAch?.toString() || '';
  }

  const params = new URLSearchParams({
    success: 'true',
    resultCode: 'Authorised',
    paymentMethod: hppData.paymentMethod || '',
    amount: sessionDetails.amount.toString(),
    fee,
    currency: sessionDetails.currency,
    referenceId: sessionDetails.referenceId,
  });

  const [urlWithoutQuery, returnUrlSearch] = returnUrl.split('?');

  if (returnUrlSearch) {
    const returnUrlParams = new URLSearchParams(returnUrlSearch);
    returnUrlParams.forEach((value, key) => {
      params.set(key, value);
    });
  }

  if (sessionDetails.referenceData) {
    params.set('referenceData', sessionDetails.referenceData);
  }

  if (sessionDetails.paymentAttributes) {
    const paymentAttributeString = JSON.stringify(sessionDetails.paymentAttributes);
    const base64 = Buffer.from(paymentAttributeString).toString('base64');
    params.set('paymentAttributes', base64);
  }

  if (hppData.paymentMethodBrand) params.set('cardBrand', hppData.paymentMethodBrand);

  return `${urlWithoutQuery}?${params.toString()}`;
};
