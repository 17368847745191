import { BrandType, useNotifications } from '@appliedsystems/applied-design-system';
import { useIsMutating, useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ApiClient } from '../api/ApiClient';
import { usePaymentsTranslation } from './usePaymentsTranslation';

export const useDisableStoredPaymentMethod = () => {
  const queryClient = useQueryClient();
  const { addNotification } = useNotifications();
  const { t } = usePaymentsTranslation();
  const disableStoredPaymentMethodMutation = useMutation({
    mutationKey: ['disableStoredPaymentMethod'],
    mutationFn: async (recurringDetailReference: string) => {
      const response = await ApiClient.getInstance().disableStoredPaymentMethod(recurringDetailReference);

      if (response.status !== 'ok') {
        console.error('Failed to disable stored payment method', response);
        throw new Error('Failed to disable stored payment method');
      }
    },
    onSuccess: async () => {
      addNotification({
        type: BrandType.Success,
        title: t('SUCCESS'),
        time: '',
        content: t('STORED_PAYMENT_METHOD_DELETED'),
      });
      queryClient.invalidateQueries(['paymentMethods']);
    },
    onError: () => {
      addNotification({
        type: BrandType.Error,
        title: t('ERROR'),
        time: '',
        content: t('FAILED_TO_DELETE_STORED_PAYMENT_METHOD'),
      });
    },
  });

  const onDisableStoredPaymentMethod = useCallback(
    async (recurringDetailReference: string, resolve?: () => void, reject?: () => void) => {
      disableStoredPaymentMethodMutation.mutate(recurringDetailReference, {
        onError: () => {
          reject?.();
        },
        onSuccess: () => {
          resolve?.();
        },
      });
    },
    [disableStoredPaymentMethodMutation],
  );

  return {
    onDisableStoredPaymentMethod,
    isLoading: disableStoredPaymentMethodMutation.isLoading,
  };
};

/*
 * This hook is used to determine when the stored payment method was last updated.
 * It is used to determine when to reinitialize the checkout form.
 */
export const useDisableStoredPaymentMethodLastUpdatedDate = () => {
  const disableStoredPaymentMethodMutationQueryCount = useIsMutating({
    mutationKey: ['disableStoredPaymentMethod'],
  });
  const addStoredPaymentMethodMutationQueryCount = useIsMutating({
    mutationKey: ['completeStorePaymentMethodSession'],
  });
  const isMutating = !!disableStoredPaymentMethodMutationQueryCount || !!addStoredPaymentMethodMutationQueryCount;
  const prevIsMutating = useRef(isMutating);
  const [lastUpdatedDate, setLastUpdatedDate] = useState<Date | null>(null);

  useEffect(() => {
    if (isMutating && !prevIsMutating.current) {
      setLastUpdatedDate(new Date());
    }
    prevIsMutating.current = isMutating;
  }, [isMutating]);

  return { storedPaymentMethodsLastUpdatedDate: lastUpdatedDate };
};
