import { Flex } from '@appliedsystems/applied-design-system';
import { PaymentMethod, TransactionType } from '@appliedsystems/payments-core';
import React from 'react';
import achIcon from '../../assets/icons/ach.svg';
import creditCardIcon from '../../assets/icons/credit-card.svg';
import premiumFinanceIcon from '../../assets/icons/premium-finance.svg';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { AvailableTransactionTypes, HppData, PfInfo } from '../HostedPaymentPageContainer/types';
import { useHppDataStore } from '../HostedPaymentPageContainer/useHppData';
import { PaymentOptionCard } from '../PaymentOptionCard/PaymentOptionCard';
import classes from './PaymentOptionContainer.module.scss';

type PaymentOptionDetails = {
  [key in AvailableTransactionTypes]: {
    icon: string;
    title: string;
    tooltip?: string;
  };
};

type Props = {
  pfInfo: PfInfo;
  onPaymentOptionSelected: (paymentDetails: Pick<HppData, 'paymentOption'>) => void;
};

export const PaymentOptionContainer = ({ pfInfo, onPaymentOptionSelected }: Props): React.ReactElement => {
  const { t } = usePaymentsTranslation();
  const { hppData } = useHppDataStore();

  const paymentOptions: PaymentOptionDetails = {
    [TransactionType.PAID_IN_FULL]: {
      icon: hppData.paymentMethod === 'ach' ? achIcon : creditCardIcon,
      title: t('PAY_IN_FULL_TODAY'),
    },
    [TransactionType.PREMIUM_FINANCE]: {
      icon: premiumFinanceIcon,
      title: t('PAY_WITH_FINANCING'),
      tooltip: t('PAY_WITH_FINANCING_TOOLTIP'),
    },
  };

  const availablePaymentOptions: AvailableTransactionTypes[] = [
    TransactionType.PAID_IN_FULL,
    TransactionType.PREMIUM_FINANCE,
  ];

  return (
    <>
      <Flex className={classes.container}>
        {availablePaymentOptions.map((paymentOptionKey) => {
          const { icon, title, tooltip } = paymentOptions[paymentOptionKey];

          return (
            <>
              <PaymentOptionCard
                key={paymentOptionKey}
                icon={icon}
                title={title}
                subtotal={hppData.paymentAmount}
                total={hppData.paymentMethod ? hppData.paymentTotal : hppData.paymentAmount}
                isFeeDetermined={hppData.paymentMethod ? true : false}
                fee={hppData.paymentMethod ? hppData.paymentFee : 0}
                showFeeAsPercentage={hppData.paymentMethod === PaymentMethod.Card}
                isActiveCard={hppData.paymentOption === paymentOptionKey}
                paymentOption={paymentOptionKey}
                tooltip={tooltip}
                pfInfo={paymentOptionKey === TransactionType.PREMIUM_FINANCE ? pfInfo : undefined}
                choosePaymentMethod={() => {
                  if (hppData.paymentOption !== paymentOptionKey) {
                    onPaymentOptionSelected({
                      paymentOption: paymentOptionKey,
                    });
                  }
                }}
              />
            </>
          );
        })}
      </Flex>
    </>
  );
};
