import {
  ADSChangeEvent,
  Button,
  Checkbox,
  Col,
  Flex,
  Modal,
  ModalFooter,
  Row,
  TextField,
} from '@appliedsystems/applied-design-system';
import { UnsignedPfa } from '@appliedsystems/payments-core';
import PDFViewer from 'pdf-viewer-reactjs';
import React, { memo, useState } from 'react';
import { usePaymentsTranslation } from '../../../src/hooks/usePaymentsTranslation';
import { useHppDataStore } from '../HostedPaymentPageContainer/useHppData';
import classes from './ESignModal.module.scss'; // Import the CSS module

type Props = {
  isOpenEsignModal: boolean;
  onClose: () => void;
  base64Pfa: UnsignedPfa;
  isPfaSigned: boolean;
  setIsPfaSigned: (isSigned: boolean) => void;
  signeeName: string;
  signeePhoneNumber: string;
};
// https://www.npmjs.com/package/pdf-viewer-reactjs
type PdfViewerProps = {
  document: string; //pdf file encoded in base64
  canvasCss: string; // css classes that will be on pdf page specifically, NOT pdf container
  scale: number; // how zoomed in the pdf is, 1.5 is good value for this modal
  externalInput: boolean; // setting to true gets rid of navbar used to change pageNumber, scale
};

const MemoizedPDFViewer = memo(({ document, canvasCss, scale, externalInput }: PdfViewerProps) => {
  return <PDFViewer document={{ url: document }} canvasCss={canvasCss} scale={scale} externalInput={externalInput} />;
});

export const AddESignModal = ({
  isOpenEsignModal,
  onClose,
  base64Pfa,
  isPfaSigned,
  setIsPfaSigned,
  signeeName,
  signeePhoneNumber,
}: Props) => {
  const { t } = usePaymentsTranslation();

  const [nameModalValue, setModalNameValue] = useState<string>(signeeName);
  const [phoneModalValue, setModalPhoneValue] = useState<string>(signeePhoneNumber);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const { hppData, setHppData } = useHppDataStore();

  const handleCheckboxChange = (e: ADSChangeEvent) => {
    setIsChecked(e.target.value); // Use e.target.checked for checkbox inputs
  };
  const currentTime = new Date().toISOString();
  const handleSignedClick = () => {
    setIsPfaSigned(true);
    setHppData({
      pf: hppData.pf?.quoteId
        ? {
            ...hppData.pf,
            signeeInfo: {
              name: nameModalValue,
              phoneNumber: phoneModalValue,
              timeStamp: currentTime,
            },
            financeableInvoices: hppData.pf?.financeableInvoices ?? [],
          }
        : undefined,
    });
    onClose();
  };

  return (
    <Modal onClose={onClose} title={t('REVIEW_PREMIUM_FINANCE')} open={isOpenEsignModal}>
      <div className={classes.pdfContainer}>
        <div className={classes.pdfViewerWrapper}>
          <MemoizedPDFViewer document={base64Pfa} canvasCss={classes.pdfViewer} scale={1.5} externalInput={true} />
        </div>
      </div>
      <div className={classes.modalText}>
        <Checkbox
          name="assertPFAgreement"
          value={isChecked || isPfaSigned}
          onChange={handleCheckboxChange}
          type="reverse"
          disabled={isPfaSigned}
        >
          {t('ASSERT_PF_AGREEMENT')}
        </Checkbox>
      </div>

      <Flex className={classes.modalText}>
        <p>{t('ENTER_NAME_NUMBER')}</p>

        <Row>
          <Col xs={12} sm={6}>
            <div style={{ width: '100%' }}>
              <TextField
                name="name"
                label={t('NAME')}
                value={nameModalValue}
                onChange={(e) => setModalNameValue(e.target.value)}
                disabled={isPfaSigned}
              />
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <div style={{ width: '100%' }}>
              <TextField
                name="phoneNumber"
                label={t('PHONE_NUMBER')}
                value={phoneModalValue}
                onChange={(e) => setModalPhoneValue(e.target.value)}
                disabled={isPfaSigned}
              />
            </div>
          </Col>
        </Row>
      </Flex>

      <ModalFooter>
        <div className={classes.buttonContainer}>
          <Button type="tertiary" onClick={onClose}>
            {t('CANCEL')}
          </Button>
          <Button
            type="primary"
            onClick={handleSignedClick}
            disabled={!(nameModalValue.trim() !== '' && phoneModalValue.trim() !== '' && isChecked)}
          >
            {t('AGREE_SIGN')}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
